.wall{
  // background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/18515/PIA09959-1280x800_copy.jpg)!important;
  background: url(https://wallpaper-mania.com/wp-content/uploads/2018/09/High_resolution_wallpaper_background_ID_77701653771.jpg) !important;
  background-size: cover!important;
}

// html, body{
//   // height: 65%;
//   // width: 100%;
// }

body{
  // background: #000;
  text-align: center!important;
}

body:before{
  content: ''!important;
  display: inline-block!important;
  height: 100%!important;
  vertical-align: middle!important;
}

.scene {
  display: inline-block;
  vertical-align: middle;
  perspective: 5px;
  perspective-origin: 50% 50%;
  position: relative;
  transition: all 1s linear;
}

.wrap{
  position: absolute;
  width: 1000px;
  height: 1000px;
  left: -500px;
  top: -500px;
  transform-style: preserve-3d;
  animation: move 12s infinite linear;
  animation-fill-mode: forwards;
}

.wrap:nth-child(2){
  animation: move 12s infinite linear;
  animation-delay: 6s;
}

.wall {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  animation: fade 12s infinite linear;
  animation-delay: 0;
}

.wrap:nth-child(2) .wall {
  animation-delay: 6s;
}

.wall-right {
  transform: rotateY(90deg) translateZ(500px);
}

.wall-left {
  transform: rotateY(-90deg) translateZ(500px);
}

.wall-top {
  transform: rotateX(90deg) translateZ(500px);
}

.wall-bottom {
  transform: rotateX(-90deg) translateZ(500px);
}

.wall-back {
  transform: rotateX(180deg) translateZ(500px);
}

@keyframes move {
  0%{
    transform: translateZ(-500px) rotate(0deg);
  }
  100%{
    transform: translateZ(500px) rotate(0deg);
  }
}

@keyframes fade {
  0%{
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
.animepmodal {
  overflow-y: hidden !important;
}
.animemodal .modal-content {
  background-color: transparent;
  border: none;
}
.gifpmodal {
  overflow-y: hidden;
  // top: -10em;
}

.gifmaker {
  background: url(https://cdn.dribbble.com/users/396778/screenshots/2051962/media/c9bdff7c0520eb7e0177a137963a6ad4.gif) no-repeat center;
  width: auto;
  height: 30em;
}

.gifmaker1 {
  background: url(https://c.tenor.com/Gqy4WGxrMv0AAAAC/try-again.gif) no-repeat center;
  width: auto;
  height: 30em;
}
.mbview {
  display: block !important;
  color: #0a0a0a !important;
  padding: 0.5em !important;
}
@media only screen and (min-width: 1024px) and (max-width: 1224px) {
  .ipmarginleftset {
    margin-left: 3em !important;
  }
}
@media only screen and (min-width: 1024px) {
  .dtview {
    display: none !important;
  }
}