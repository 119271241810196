.c-indicator-pos {
    div{
        ol{
            bottom: -60px!important;
            position: absolute;
            margin-bottom: 20px!important;
            // li{ //styling
            //     background-color: red;
            //     border-radius: 50%;
            //     height: 10px;
            //     width: 10px;
            // }
        }
    }
}

.c-indicator-pos {
    .carousel{
        .carousel-caption{
            // bottom: 0px!important;
            h3{ 
                display: unset!important;
                background: rgba(0,0,0,0.8)!important;
                border-radius:15px!important;
                padding:10px!important;
            }
            span {
                .btn-danger {
                    margin-top:2em!important;
                }
            }
        }
    }
}

body {
    background-image: url('https://wallpapercave.com/wp/wp6690890.jpg') !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: center !important;
    background-size: cover !important;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.6) !important;
}

.buttonTitle { 
    font-family: "Roboto Mono",monospace !important;
    font-size: 1.8em !important;
    font-weight: 400 !important;
    // letter-spacing: 0 !important;
    // line-height: 35px !important;
}
.buttonFightTitle { 
    font-family: "Raleway" !important;
    font-size: 1.2em !important;
    font-weight: 500 !important;
    // letter-spacing: 0 !important;
    text-transform: uppercase !important;
    color: #ffa502 !important;
}
.buttonFightTitleGame { 
    font-family: "Raleway" !important;
    font-size: 0.9em !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    color: #ffa502 !important;
}
.btn-fighter {
    border: 2px solid #ffa502 !important;
    background: transparent !important;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.8) !important;
    //margin-top: 2em !important;
}
.btn-fighter-game {
    border: 2px solid #ffa502 !important;
    background: transparent !important;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.8) !important;
}

.numberFont {
    font-family: "Roboto Mono",monospace !important;
}

.miniHeaderFont {
    font-weight: 700 !important;
    text-transform: uppercase !important;
    font-family: "Raleway",sans-serif !important;
}

.cardHeaderFont {
    font-family: "Raleway",sans-serif !important;
    font-weight: 400 !important;
}

.cardHeaderFont {
    font-family: "Raleway",sans-serif !important;
    font-weight: 400 !important;
}

.cardBodyFont {
    font-family: "Nunito",sans-serif !important;
    font-weight: 200 !important;
}

.bolderText {
    font-weight: bold !important;
}
.fw400 { 
    font-weight: 400 !important;
}

.f40 {
    font-size: 40px !important;
}

.f30 {
    font-size: 30px !important;
}

.f25 {
    font-size: 25px !important;
}

.f20 {
    font-size: 20px !important;
}

.f18 {
    font-size: 18px !important;
}

.fw500 {
    font-weight: 500 !important;
}

.table > tbody > tr > td {
    color: rgba(255, 255, 255, 1) !important;
    // font-family: "Roboto Mono",monospace !important;
    font-family: "Nunito",sans-serif !important;
    font-size: 20px !important;
}

.tbBorderNone {
    border: none!important;
}